import { getHubSpot } from './getGlobal';
import { MetricsFactory } from './MetricsFactory';
let factory;

function setSetupComplete(newValue) {
  getHubSpot().__metricsSetupComplete = newValue;
}

function getSetupComplete() {
  return getHubSpot().__metricsSetupComplete;
}

export function trackMetricsLoaded() {
  if (factory || getSetupComplete()) {
    return;
  }

  factory = new MetricsFactory('metrics', {});
  factory.counter('loaded').increment();
  setSetupComplete(true);
}